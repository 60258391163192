
export default class Footer {
  constructor() {
    this.pagetopIcon = document.getElementById('pagetop');
    this.pagetop();
  }

  pagetop() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        this.pagetopIcon.style.opacity = 1;
      } else {
        this.pagetopIcon.style.opacity = 0;
      }
    });
  }

}

