export default class Accordion {
  constructor() {

    this.accordions = [...document.querySelectorAll('.accordion > .accordion__btn')];
		this.accordions.forEach(item => {
			item.addEventListener('click', this.spnavsubClick.bind(this));
    });

    this.init();
  }

  init(){

  }


  spnavsubClick(e) {
    e.preventDefault();
    let target = e.target;
    let parent = target.closest('.accordion');
    let child = parent.querySelector('.accordion__body');
    if(!parent.classList.contains("is_active")) {
      parent.classList.add('is_active');
      child.style.height = child.scrollHeight + 'px';
    } else {
      parent.classList.remove('is_active');
      child.style.height = 0;
    }
  }

}
