/**
 * ニュースリリースの絞り込み機能を管理するクラス
 * @class NewsRelease
 */
export default class NewsRelease {
  /** @type {HTMLSelectElement} 年フィルターのセレクト要素 */
  yearSelect;

  /** @type {HTMLSelectElement} カテゴリフィルターのセレクト要素 */
  categorySelect;

  /** @type {NodeListOf<HTMLElement>} 年別のニュースセクション要素のリスト */
  yearSections;

  /** @type {NodeListOf<HTMLElement>} ニュースブロック要素のリスト */
  newsBlocks;

  /** @type {string} 最新の年 */
  currentYear;

  constructor() {
    this.currentYear = new Date().getFullYear().toString();

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  /**
   * 初期化処理
   * @private
   */
  init() {
    this.yearSelect = document.querySelector('.news-release__filter-year select');
    this.categorySelect = document.querySelector('.news-release__filter-category select');
    this.yearSections = document.querySelectorAll('.news-release__year');
    this.newsBlocks = document.querySelectorAll('.news-release__block');

    if (!this.validateElements()) {
      return;
    }

    if (!this.validateCurrentYear()) {
      this.currentYear = this.getLatestYearFromSelect();
    }

    this.setInitialDisplay();
    this.attachEventListeners();
  }

  /**
   * セレクトボックスから最新の年を取得
   * @private
   * @returns {string} 最新の年
   */
  getLatestYearFromSelect() {
    const options = Array.from(this.yearSelect.options);
    const years = options
      .map(option => option.value)
      .filter(value => value !== '')
      .map(Number);

    return Math.max(...years).toString();
  }

  /**
   * 現在の年がセレクトボックスに存在するか確認
   * @private
   * @returns {boolean} 存在する場合はtrue
   */
  validateCurrentYear() {
    return Array.from(this.yearSelect.options)
      .some(option => option.value === this.currentYear);
  }

  /**
   * 必須要素の存在チェック
   * @private
   * @returns {boolean} 全ての必須要素が存在する場合はtrue
   */
  validateElements() {
    if (!this.yearSelect || !this.categorySelect) {
      console.warn('Required select elements not found');
      return false;
    }
    if (!this.yearSections.length) {
      console.warn('Year sections not found');
      return false;
    }
    return true;
  }

  /**
   * 初期表示の設定
   * @private
   */
  setInitialDisplay() {
    const params = new URLSearchParams(window.location.search);
    const year = params.get('year');
    const category = params.get('category');

    if (year) {
      this.yearSelect.value = year;
    } else {
      this.yearSelect.value = this.currentYear;
    }

    if (category) {
      this.categorySelect.value = category;
    }

    this.filterNews();
  }

  /**
   * すべての要素を表示状態にする
   * @private
   */
  showAllItems() {
    this.newsBlocks.forEach(block => block.style.display = '');
    this.yearSections.forEach(section => section.style.display = '');
    document.querySelectorAll('.month-li').forEach(header => {
      header.style.display = '';
    });
  }

  /**
   * イベントリスナーの設定
   * @private
   */
  attachEventListeners() {
    const handleFilter = () => {
      this.showAllItems();
      this.filterNews();
    };

    this.yearSelect.addEventListener('change', handleFilter);
    this.categorySelect.addEventListener('change', handleFilter);
  }

  /**
   * 月見出しの表示状態を更新
   * @private
   * @param {HTMLElement} monthHeader - 月見出し要素
   */
  updateMonthHeaderVisibility(monthHeader) {
    const nextSibling = monthHeader.nextElementSibling;
    let hasVisibleNews = false;

    // 次の月見出しが来るまでのニュース記事をチェック
    let currentElement = nextSibling;
    while (currentElement && !currentElement.classList.contains('month-li')) {
      if (currentElement.classList.contains('news-release__block') &&
          currentElement.style.display !== 'none') {
        hasVisibleNews = true;
        break;
      }
      currentElement = currentElement.nextElementSibling;
    }

    monthHeader.style.display = hasVisibleNews ? '' : 'none';
  }

  /**
   * ニュースの絞り込みを実行
   * @private
   */
  filterNews() {
    const selectedYear = this.yearSelect.value;
    const selectedCategory = this.categorySelect.value;

    this.yearSections.forEach(yearSection => {
      const yearMatch = !selectedYear || yearSection.id === `n${selectedYear}`;
      yearSection.style.display = yearMatch ? '' : 'none';

      if (yearMatch) {
        // ニュース記事の表示/非表示を設定
        const newsBlocks = yearSection.querySelectorAll('.news-release__block');
        newsBlocks.forEach(block => {
          const categoryMatch = !selectedCategory || block.classList.contains(selectedCategory);
          block.style.display = categoryMatch ? '' : 'none';
        });

        // 月見出しの表示/非表示を更新
        const monthHeaders = yearSection.querySelectorAll('.month-li');
        monthHeaders.forEach(header => {
          this.updateMonthHeaderVisibility(header);
        });
      }
    });
  }

  /**
   * フィルターのリセット
   * @public
   */
  resetFilters() {
    this.yearSelect.value = this.currentYear;
    this.categorySelect.value = '';
    this.filterNews();
  }

  /**
   * 表示中の記事数を取得
   * @returns {number} 表示中の記事数
   * @public
   */
  getVisibleItemsCount() {
    return document.querySelectorAll('.news-release__block[style=""]').length;
  }
}
