// import Swiper JS
import Swiper from "swiper/bundle";

export default class PreFooter {
  constructor() {
    const page = document.querySelector(".recruit");
    const page2 = document.querySelector(".opening");
    const page3 = document.querySelector(".company");
    if (!page && !page2 && !page3) return;

    this.init();
  }

  init() {
    this.loading();
  }
  loading() {
    const length = document.querySelector(".swiper-wrapper").childElementCount;
    const elem = document.getElementById("preFooterWrapper");

    const btnPrev = document.querySelector(".swiper-button-prev");
    const btnNext = document.querySelector(".swiper-button-next");

    let spLoop = false;
    let pcLoop = false;

    let slidesPerViewNum = 3;
    let centeredSlidesFlag = false;

    if (length >= 3) {
      spLoop = true;
    }

    if (length >= 4) {
      pcLoop = true;
      slidesPerViewNum = 3;
      centeredSlidesFlag = true;

      // elem.classList.remove("l-contents--wide");
      // elem.classList.add("l-contents--mix");
    }

    const swiperOptions = {
      slidesPerView: 2,
      spaceBetween: 32,
      loop: spLoop,
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1000: {
          slidesPerView: slidesPerViewNum,
          spaceBetween: 60,
          loop: pcLoop,
          centeredSlides: centeredSlidesFlag,
        },
      },
    };

    const swiperDom = document.querySelector(".swiper");
    let swiper;

    const createSwiper = () => {
      if (!swiper) swiper = new Swiper(swiperDom, swiperOptions);
      // console.log("createSwiper");
    };

    const destroySwiper = () => {
      if (swiper) {
        swiper.destroy(false, true);
        swiper = null;
        // console.log("destroySwiper");
      }
    };
    const handleMediaQuery = (event) => {
      // console.log("handleMediaQuery");
      destroySwiper();
      createSwiper();

      if (event.matches) {
        //SPの時
        if (length >= 3) {
          // destroyしても表示されないので、強制表示
          btnPrev.classList.remove("swiper-button-lock");
          btnNext.classList.remove("swiper-button-lock");
        }
      }
      // if (event.matches) {
      //   destroySwiper();
      //   createSwiper();
      //   //
      //   if (length >= 3) {
      //     // destroyしても表示されないので、強制表示
      //     btnPrev.classList.remove("swiper-button-lock");
      //     btnNext.classList.remove("swiper-button-lock");
      //   }
      // } else {
      //   destroySwiper();
      //   createSwiper();
      // }
    };

    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    window.addEventListener("load", () => handleMediaQuery(mediaQuery));
    mediaQuery.addEventListener("change", handleMediaQuery);
  }
}
