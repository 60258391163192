import { gsap } from 'gsap';

/**
 * グローバルナビゲーションを制御するクラス
 * PC/SPの切り替えやドロップダウンメニュー、検索機能などを管理
 */
export default class Gnav {
  constructor() {
    // スクロール位置保存用
    this.currentY = 0;
    // ナビゲーションの開閉状態
    this.navFlag = false;
    // 現在選択中のターゲット要素
    this.ctarget = '';

    // DOM要素の初期化
    this.initializeElements();
    // イベントリスナーの設定
    this.setupEventListeners();
    // 初期化処理の実行
    this.init();
  }

  /**
   * DOM要素の初期化
   */
  initializeElements() {
    this.hamburger = document.querySelectorAll('.js-hamburger');
    this.pcGnav = document.querySelector('.js-pc-gnav');
    this.hamburgerTrigger = document.querySelectorAll('.js-hamburger-trigger');
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.searchArea = document.querySelector('.js-gnav-search-input');
    this.searchFlg = false;
  }

  /**
   * イベントリスナーの設定
   */
  setupEventListeners() {
    // ハンバーガーメニューのクリックイベント
    [...this.hamburgerTrigger].forEach(elm => {
      elm.addEventListener('click', this.toggleMenu.bind(this));
    });

    // 検索機能のイベント
    document.querySelector('.js-gnav-search').addEventListener('click', this.toggleSearch.bind(this));
    document.addEventListener('click', this.closeSearch.bind(this));
  }

  /**
   * 初期化処理
   * サブナビゲーションの要素取得とマウスイベントの設定
   */
  init() {
    this.resize();

    // サブナビゲーション関連の要素取得
    this.navHasSubs = [...document.querySelectorAll('[data-subnav-has]')];
    this.navsubs = [...document.querySelectorAll('[data-subnav]')];

    // マウスオーバーイベントの設定
    [...this.navHasSubs, ...this.navsubs].forEach(item => {
      item.addEventListener('mouseover', this.navsubMouseover.bind(this));
    });

    // マウスリーブイベントの設定
    document.querySelector('.gnav-nav__list').addEventListener('mouseleave', this.navsubMouseleave.bind(this));
  }

  /**
   * リサイズ時のPC/SP表示切り替え処理
   */
  resize() {
    const mediaQueryList = window.matchMedia('(max-width: 1450px)');

    const listener = (event) => {
      if (event.matches) {
        // SP表示時の処理
        this.switchToSpView();
      } else {
        // PC表示時の処理
        this.switchToPcView();
      }
    };

    mediaQueryList.addEventListener("change", listener);
    listener(mediaQueryList); // 初期化時の実行
  }

  /**
   * SP表示への切り替え
   */
  switchToSpView() {
    [...this.hamburger].forEach(elm => {
      elm.classList.remove('visually-hidden');
      elm.classList.add('visually-show');
    });
    this.pcGnav.classList.remove('visually-show');
    this.pcGnav.classList.add('visually-hidden');
  }

  /**
   * PC表示への切り替え
   */
  switchToPcView() {
    [...this.hamburger].forEach(elm => {
      elm.classList.remove('visually-show', 'is-active');
      elm.classList.add('visually-hidden');
    });
    this.pcGnav.classList.remove('visually-hidden');
    this.pcGnav.classList.add('visually-show');
    this.body.classList.remove('is_toggle-open');
    this.html.classList.remove('is_toggle-open');
    this.navFlag = false;
  }

  /**
   * ハンバーガーメニューの開閉処理
   */
  toggleMenu() {
    if (!this.navFlag) {
      // メニューを開く
      this.currentY = window.scrollY || window.pageYOffset;
      this.navFlag = true;
      [...this.hamburger].forEach(elm => elm.classList.add('is-active'));
      this.body.classList.add('is_toggle-open');
      this.html.classList.add('is_toggle-open');
    } else {
      // メニューを閉じる
      [...this.hamburger].forEach(elm => elm.classList.remove('is-active'));
      this.body.classList.remove('is_toggle-open');
      this.html.classList.remove('is_toggle-open');
      this.navFlag = false;
      window.scrollTo(0, this.currentY);
    }
  }

  /**
   * 検索エリアの開閉処理
   */
  toggleSearch(e) {
    e.stopPropagation();
    this.searchFlg = !this.searchFlg;
    this.searchArea.classList.toggle('is-active', this.searchFlg);
  }

  /**
   * 検索エリアの外側クリック時の閉じる処理
   */
  closeSearch(e) {
    if (this.searchFlg && !this.searchArea.contains(e.target)) {
      this.searchFlg = false;
      this.searchArea.classList.remove('is-active');
    }
  }

  /**
   * サブナビゲーションのマウスオーバー処理
   * @param {Event} e - マウスオーバーイベント
   */
  navsubMouseover(e) {
    const ctarget = e.currentTarget;
    this.ctarget = ctarget;
    const btns = this.getElement(ctarget, 'btn');
    const nav = this.getElement(ctarget);

    // 既存のアクティブ状態をリセット
    this.resetActiveStates();

    // ニュースリリースの場合はオーバーレイも非表示にして処理を中断
    if (ctarget.getAttribute('data-subnav-has') === 'news') {
      document.querySelector('.gnav-overlay').classList.remove('is-active');
      return;
    }

    // 新しい要素をアクティブに
    this.setActiveStates(ctarget, nav, btns);

    // アニメーション
    if (nav) {
      gsap.killTweensOf(nav);
      gsap.to(nav, {
        duration: 0.0,
        autoAlpha: 1,
        ease: "power4.out",
      });
    }
  }

  /**
   * アクティブ状態のリセット
   */
  resetActiveStates() {
    [...this.navHasSubs].forEach(elm => {
      if (elm.classList.contains('is-active')) {
        elm.classList.remove('is-active');
        const megaDropNav = elm.querySelector('.mega-drop-nav');
        if (megaDropNav) {
          gsap.to(megaDropNav, {
            duration: 0.0,
            autoAlpha: 0,
            ease: "power4.out",
          });
        }
      }
    });
  }

  /**
   * 要素のアクティブ状態の設定
   */
  setActiveStates(ctarget, nav, btns) {
    ctarget.classList.add('is-active');
    if (nav) {
      ctarget.closest('.gnav-nav__list').classList.add('is-active');
      nav.classList.add('is-active');
      document.querySelector('.gnav-overlay').classList.add('is-active');
    }
    if (ctarget.dataset.subnav) {
      btns?.classList.add('is-active');
    }
  }

  /**
   * サブナビゲーションのマウスリーブ処理
   */
  navsubMouseleave() {
    // サブナビゲーションのリセット
    [...this.navsubs].forEach(elm => {
      if (elm.classList.contains('is-active')) {
        elm.classList.remove('is-active');
        gsap.to(elm, {
          duration: .0,
          autoAlpha: 0,
          ease: "power4.out",
        });
      }
    });

    // アクティブ状態の解除
    [...this.navHasSubs].forEach(elm => {
      elm.classList.remove('is-active');
    });

    // オーバーレイの非表示
    document.querySelector('.gnav-overlay').classList.remove('is-active');
    document.querySelector('.gnav-nav__list').classList.remove('is-active');
  }

  /**
   * 要素の取得
   * @param {Element} target - 対象要素
   * @param {string} type - 取得する要素のタイプ
   * @returns {Element} - 取得した要素
   */
  getElement(target, type = null) {
    const closestHeader = document.getElementById('gnav');
    const cat = target.getAttribute('data-subnav-has') || target.getAttribute('data-subnav');

    if (type === 'btn') {
      return closestHeader.querySelector(`[data-subnav-has="${cat}"]`);
    }
    return closestHeader.querySelector(`[data-subnav="${cat}"]`);
  }
}
