import Common from "./pages/common.js";
// import Header from './modules/header.js';
import Top from "./pages/top.js";
import Recruit from "./pages/recruit.js";
import TenantApplication from "./pages/tenant_application.js";
import NewsReleaseSort from "./pages/news_release_sort.js";
import NewsReleaseMarker from "./pages/news_release_marker.js";

class App {
  constructor() {
    this.routes();
  }

  routes() {
    new Common();

    switch (location.pathname) {
      case "/":
        new Top();
        break;

      case "/news_release/":
        new NewsReleaseSort();
        new NewsReleaseMarker();
        break;
      case "/tenant/application/":
        new TenantApplication();
        break;

      // case '/recruit/':
      // case '/recruit/index.html':
      // case "/recruit/index2.html":
      // case "/recruit/index3.html":
      //   new Recruit();
      //   break;

      default:
    }
  }
}

window.App = new App();
