/**
 * Common Page
 * @class Common
 */

import Gnav from "../modules/gnav.js";
// import Gnav02 from '../modules/gnav_02.js'; // ワイヤー
import Hover from "../modules/hover.js";
import PreFooter from "../modules/pre_footer.js";
import Footer from "../modules/footer.js";
import Accordion from "../modules/accordion.js";
import SmoothScroll from "../modules/smoothscroll.js";
import Modal from "../modules/modal.js";

export default class Common {
  constructor() {
    this.init();
  }

  init() {
    new SmoothScroll();
    new PreFooter();
    new Footer();
    new Accordion();
    new Modal();
    new Gnav();
    new Hover();
  }
}
