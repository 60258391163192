// import Swiper JS
import Swiper from "swiper/bundle";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/**
 * Top Page
 * @class Top
 *
 * トップページの機能を管理するクラス
 * 主な機能:
 * - KVエリア(hero)のスライダー管理
 * - TOPICSエリアのスライダー管理
 * - YouTube動画の制御
 * - ニュースカテゴリの切り替え
 * - アニメーション効果の適用
 */

export default class Top {
  constructor() {
    const page = document.querySelector(".top");
    if (!page) return;
    const hero = document.querySelector(".swiper-hero");
    if (!hero) return;

    // ========== KVエリア(hero)の状態管理 ==========
    this.swiperHero = "";
    this.isPause = false;
    this.playBtn = document.querySelector(".js-swiper-controle-btn-play");
    this.stopBtn = document.querySelector(".js-swiper-controle-btn-stop");
    this.control = [...document.querySelectorAll(".js-swiper-controle-btn")];
    this.control.forEach((item) => {
      item.addEventListener("click", this.togglePlay.bind(this));
    });

    // モーダル関連の状態
    this.isModalOpen = false;
    this.isYTModalOpen = false;
    this.heroPrevListener = false;
    this.swiperHeroActiveIndex = 0;
    this.swiperHeroLastIndex = "";
    this.isVideoPlaying = false;

    // ========== TOPICSエリアの状態管理 ==========
    this.swiperTopics = "";
    this.topicsPause = false;
    this.topicsPlayBtn = document.querySelector(".js-swiper-button-play");
    this.topicsStopBtn = document.querySelector(".js-swiper-button-stop");
    this.topicsControl = [
      ...document.querySelectorAll(".js-topics-controle-btn"),
    ];
    this.topicsControl.forEach((item) => {
      item.addEventListener("click", this.topicsTogglePlay.bind(this));
    });

    // ========== YouTube関連の状態管理 ==========
    this.ytPlayer = "";
    this.ytPlayerHero = "";
    this.ytPlayerHeroModal = "";

    // ========== ニュース関連の状態管理 ==========
    this.selectedNewsCategory = "";
    // ニュースカテゴリの定義
    this.newsCategory = [
      "all", // すべて
      "important_info", // 重要なお知らせ
      "ir_info", // IR情報
      "sus_info", // サステナビリティ
      "group_info", // グループ情報
      "product_info", // 商品情報
      "organize_info", // 組織情報
    ];
    this.toggleNewsBtn = [
      ...document.querySelectorAll(".top-news__category > li"),
    ];
    this.toggleNewsBtn.forEach((item) => {
      item.addEventListener("click", this.toggleNews.bind(this));
    });

    // 初期化処理の実行
    this.init();
  }

  init() {
    this.loading(); // Swiperの初期化
    this.setYoutube(); // YouTube Playerの設定
    this.toggleNews(); // ニュース表示の初期化
    this.fadeIn(); // アニメーションの設定
  }

  loading() {
    const _this = this;
    window.onload = () => {
      // TOPICSエリアのスライダー初期化
      _this.swiperTopics = new Swiper(".swiper-topics", {
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 200,
        autoplay: {
          disableOnInteraction: false,
        },
        preventClicks: false,
        pagination: {
          el: ".swiper-pagination-topics",
          type: "progressbar",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          // when window width is >= 1000px
          1000: {
            slidesPerView: 2.5,
            spaceBetween: 144,
          },
        },
        on: {
          afterInit: function () {
            // モーダル設定
            var modal = document.querySelector(".js-top-topics-modal .popup");
            if (!modal) return;

            var blackBg = document.getElementById("js-top-topics-black-bg");
            var closeBtn = document.getElementById("js-top-topics-close-btn");
            var showBtn = document.getElementsByClassName(
              "js-show-top-topics-video",
            );

            closeModal(blackBg);
            closeModal(closeBtn);

            function closeModal(elem) {
              if (!elem) return;
              elem.addEventListener("click", function (e) {
                modal.classList.toggle("is-show");
                if (_this.isModalOpen == true) {
                  _this.ytPlayer.stopVideo();
                  _this.isModalOpen = false;
                }
              });
            }

            function menuClick(e) {
              // クリックした際に実行する処理を記述
              modal.classList.toggle("is-show");
              _this.isModalOpen = true;
            }

            // 上記で取得したすべての要素に対してクリックイベントを適用
            for (let i = 0; i < showBtn.length; i++) {
              showBtn[i].addEventListener("click", menuClick, false);
            }

            //表示する
            // console.log("int");
            const ele = document.querySelector(".top-topics__swiper");
            ele.classList.add("is-active");
          },
        },
      });

      // KV(hero)エリアのスライダー初期化
      _this.swiperHero = new Swiper(".swiper-hero", {
        centeredSlides: true,
        slidesPerView: 1,
        autoplay: {
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-hero",
          type: "progressbar",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        updateOnWindowResize: true,

        on: {
          init: function () {
            const prev = document.querySelector(".swiper-button-hero-prev");
            prev.ariaDisabled = false;
            prev.classList.remove("swiper-button-disabled");
            _this.swiperHeroLastIndex = this.slides.length - 1;
          },
          slideChange: function () {
            document.querySelector(".swiper-button-hero-prev").ariaDisabled =
              false;
            document
              .querySelector(".swiper-button-hero-prev")
              .classList.remove("swiper-button-disabled");
            document.querySelector(".swiper-button-hero-next").ariaDisabled =
              false;
            document
              .querySelector(".swiper-button-hero-next")
              .classList.remove("swiper-button-disabled");
            _this.swiperHeroActiveIndex = this.activeIndex;

            // YouTube動画の制御
            if (_this.ytPlayerHero) {
              if (_this.swiperHeroActiveIndex === _this.swiperHeroLastIndex) {
                // YouTube動画のスライドに来た時は常に最初から再生
                _this.ytPlayerHero.seekTo(0);
                _this.ytPlayerHero.playVideo();
              } else {
                // YouTube以外のスライドに移動した時
                _this.ytPlayerHero.seekTo(0);
                _this.ytPlayerHero.pauseVideo();
              }
            }

            const prev = document.querySelector('.swiper-button-hero-prev');
            const next = document.querySelector('.swiper-button-hero-next');

            prev.replaceWith(prev.cloneNode(true));
            next.replaceWith(next.cloneNode(true));

            const newPrev = document.querySelector(".swiper-button-hero-prev");
            const newNext = document.querySelector(".swiper-button-hero-next");

            newPrev.addEventListener("click", () => {
              if (_this.swiperHeroActiveIndex === 0) {
                _this.swiperHero.slideTo(_this.swiperHeroLastIndex);
              } else {
                _this.swiperHero.slidePrev();
              }
            });

            newNext.addEventListener("click", () => {
              if (_this.swiperHeroActiveIndex === _this.swiperHeroLastIndex) {
                _this.swiperHero.slideTo(0);
              } else {
                _this.swiperHero.slideNext();
              }
            });

            newPrev.ariaDisabled = false;
            newPrev.classList.remove("swiper-button-disabled");
            newNext.ariaDisabled = false;
            newNext.classList.remove("swiper-button-disabled");

            if (this.activeIndex === _this.swiperHeroLastIndex) {
              this.autoplay.stop();
            }
          },
          afterInit: function () {
            // モーダル設定
            var modal = document.querySelector(".js-swiper-hero-modal .popup");
            if (!modal) return;

            var blackBg = document.getElementById("js-swiper-hero-black-bg");
            var closeBtn = document.getElementById("js-swiper-hero-close-btn");
            var showBtn = document.querySelectorAll(".swiper-hero-yt");

            closeModal(blackBg);
            closeModal(closeBtn);

            function closeModal(elem) {
              if (!elem) return;
              elem.addEventListener("click", function (e) {
                modal.classList.toggle("is-show");
                if (_this.isYTModalOpen == true) {
                  _this.ytPlayerHeroModal.stopVideo();
                  _this.isYTModalOpen = false;
                }
              });
            }

            function menuClick(e) {
              // クリックした際に実行する処理を記述
              modal.classList.toggle("is-show");
              _this.isYTModalOpen = true;
            }

            // 上記で取得したすべての要素に対してクリックイベントを適用
            for (let i = 0; i < showBtn.length; i++) {
              showBtn[i].addEventListener("click", menuClick, false);
            }
          },
          resize: function () {
            this.scrollbar.updateSize();
            this.scrollbar.setTranslate();
          },
        },
      });
    };
  }

  /**
   * HEROエリアのスライダーの再生/停止を切り替え
   */
  togglePlay() {
    if (this.isPause) {
      if (this.swiperHeroActiveIndex !== this.swiperHeroLastIndex) {
        this.swiperHero.autoplay.start();
      }
      this.stopBtn.classList.remove("visually-hidden");
      this.playBtn.classList.add("visually-hidden");
    } else {
      this.swiperHero.autoplay.stop();
      if (
        this.swiperHeroActiveIndex === this.swiperHeroLastIndex &&
        this.ytPlayerHero
      ) {
        this.isVideoPlaying = true;
      }
      this.stopBtn.classList.add("visually-hidden");
      this.playBtn.classList.remove("visually-hidden");
    }
    this.isPause = !this.isPause;
  };

  /**
   * TOPICSエリアのスライダーの再生/停止を切り替え
   */
  topicsTogglePlay() {
    if (this.topicsPause) {
      this.swiperTopics.autoplay.start();
      this.topicsStopBtn.classList.remove("visually-hidden");
      this.topicsPlayBtn.classList.add("visually-hidden");
    } else {
      this.swiperTopics.autoplay.stop();
      this.topicsStopBtn.classList.add("visually-hidden");
      this.topicsPlayBtn.classList.remove("visually-hidden");
    }
    this.topicsPause = !this.topicsPause;
  };

  /**
   * YouTubeプレーヤーの初期化
   * 3つのプレーヤーを設定：
   * - メインのプレーヤー
   * - ヒーローエリアのプレーヤー
   * - モーダル用のプレーヤー
   */
  setYoutube() {
    const _this = this;
    YT.ready(() => {
      // hero
      _this.ytPlayerHero = new YT.Player("youtubeHero01", {
        events: {
          onStateChange: _this.onYTHeroStateChange.bind(_this),
        },
      });

      // hero modal
      _this.ytPlayerHeroModal = new YT.Player("youtubeHeroModal01");

      // topics
      _this.ytPlayer = new YT.Player("youtube01");
    });
  }

  // 再生終了時の処理
  // @see: https://developers.google.com/youtube/iframe_api_reference
  // In your code, you can specify the integer values or you can use one of the following namespaced variables:
  // YT.PlayerState.ENDED
  // YT.PlayerState.PLAYING
  // YT.PlayerState.PAUSED
  // YT.PlayerState.BUFFERING
  // YT.PlayerState.CUED
  onYTHeroStateChange(event) {
    if (
      event.data === YT.PlayerState.ENDED &&
      this.swiperHeroActiveIndex === this.swiperHeroLastIndex
    ) {
      if (this.isPause) {
        this.ytPlayerHero.seekTo(0);
        this.ytPlayerHero.playVideo();
      } else {
        this.swiperHero.autoplay.start();
        this.ytPlayerHero.seekTo(0);
        this.swiperHero.slideTo(0);
        document
          .querySelector(".js-swiper-controle-btn-stop")
          .classList.remove("visually-hidden");
        document
          .querySelector(".js-swiper-controle-btn-play")
          .classList.add("visually-hidden");
      }
    }
  }

  /**
   * ニュース表示の切り替え処理
   * @param {Event} e - クリックイベント
   */
  toggleNews(e) {
    console.log(e);
    const newsItems = document.querySelectorAll(".top-news__list > ul > li");
    const DISPLAY_LIMIT = 3;

    // 全記事を非表示にする
    this.hideAllNews(newsItems);

    if (!e) {
      // 画面初期ロード時
      this.initializeNewsDisplay(newsItems, DISPLAY_LIMIT);
    } else {
      // カテゴリークリック時
      this.updateNewsDisplay(e, newsItems, DISPLAY_LIMIT);
    }
  }

  // 全記事を非表示
  hideAllNews(newsItems) {
    [...newsItems].forEach((item) => item.classList.add("none"));
  }

  // 画面初期表示時の処理
  initializeNewsDisplay(newsItems, limit) {
    this.selectedNewsCategory = "all";
    this.displayLatestNews(newsItems, limit);
  }

  // 最新のニュースを表示
  displayLatestNews(newsItems, limit) {
    for (let i = 0; i < Math.min(newsItems.length, limit); i++) {
      newsItems[i].classList.remove("none");
    }
  }

  // カテゴリークリック時の表示更新
  updateNewsDisplay(e, newsItems, limit) {
    this.selectedNewsCategory = e.target.dataset.category;
    console.log(this.selectedNewsCategory);
    this.updateCategoryButtonStyles();

    if (this.selectedNewsCategory === "all") {
      this.displayLatestNews(newsItems, limit);
    } else {
      this.displayNewsByCategory(newsItems, limit);
    }
  }

  // カテゴリーボタンのスタイル更新
  updateCategoryButtonStyles() {
    const categoryButtons = document.querySelectorAll(".top-news__category li");
    categoryButtons.forEach((item) => item.classList.remove("selected"));
    document
      .querySelector(
        `.top-news__category li[data-category="${this.selectedNewsCategory}"]`,
      )
      .classList.add("selected");
  }

  // 選択されたカテゴリーのニュースを表示
  displayNewsByCategory(newsItems, limit) {
    let displayCount = 0;

    for (const item of newsItems) {
      if (displayCount >= limit) break;

      // クラス名を変更
      const categoryContainer = item.querySelector(".top-news__meta-category");
      const hasCategory = categoryContainer.querySelector(
        `.c-news__tag--${this.selectedNewsCategory}`,
      );

      if (hasCategory) {
        item.classList.remove("none");
        this.reorderCategories(item);
        displayCount++;
      }
    }
  }

  // カテゴリーの表示順を並び替え（選択カテゴリーを先頭に）
  reorderCategories(newsItem) {
    const categoryContainer = newsItem.querySelector(
      ".top-news__meta-category",
    );
    if (!categoryContainer) return;

    const categories = Array.from(categoryContainer.children);
    // クラス名を変更
    const selectedCategory = categories.find((item) =>
      item.classList.contains(`c-news__tag--${this.selectedNewsCategory}`),
    );

    if (selectedCategory) {
      categoryContainer.insertBefore(
        selectedCategory,
        categoryContainer.firstChild,
      );
    }
  }

  /**
   * GSAPを使用したアニメーション効果の設定
   * - 新着情報のフェードイン
   * - 事業紹介の画像アニメーション
   * - イズミについてのリンクとイメージのアニメーション
   */
  fadeIn() {
    gsap.registerPlugin(ScrollTrigger);

    // 新着情報
    gsap.from(".js-fadein-topNewsInner", {
      scrollTrigger: ".js-fadein-topNewsInner",
      opacity: 0,
      duration: 1,
      y: 90,
      delay: 0.2,
    });

    // 事業紹介
    const topBusinessImageTop = gsap.from(".js-fadein-topBusinessImageTop", {
      y: -60,
      opacity: 0,
      duration: 0.8,
      delay: 0.7,
      paused: true,
    });
    const topBusinessImageBottom = gsap.from(
      ".js-fadein-topBusinessImageBottom",
      {
        y: 60,
        opacity: 0,
        duration: 0.8,
        delay: 0.7,
        paused: true,
      },
    );
    ScrollTrigger.create({
      trigger: ".js-fadein-topBusinessImageTop",
      onEnter: () => {
        topBusinessImageTop.play();
        topBusinessImageBottom.play();
      },
      once: true,
    });
    const topBusinessImageTopSp = gsap.from(
      ".js-fadein-topBusinessImageTopSp",
      {
        y: -30,
        opacity: 0,
        duration: 1.2,
        immediateRender: false,
      },
    );
    const topBusinessImageBottomSp = gsap.from(
      ".js-fadein-topBusinessImageBottomSp",
      {
        y: 30,
        opacity: 0,
        duration: 1.2,
        immediateRender: false,
      },
    );
    ScrollTrigger.create({
      trigger: ".js-fadein-topBusinessImageTopSp",
      onEnter: () => {
        topBusinessImageTopSp.play();
        topBusinessImageBottomSp.play();
      },
      once: true,
    });

    // イズミについて
    gsap.from(".js-fadein-topAboutLinks", {
      scrollTrigger: ".js-fadein-topAboutLinks",
      opacity: 0,
      stagger: 0.2,
      duration: 1.5,
      delay: 0.5,
    });
    gsap.from(".js-fadein-topAboutImage", {
      scrollTrigger: ".js-fadein-topAboutImage",
      x: 45,
      opacity: 0,
      duration: 0.8,
      delay: 0.5,
    });
  }
}
