/**
 * SmoothScroll
 * @class SmoothScroll
 * @property {number} scrollSpeed - スクロール速度（ミリ秒単位）。
 * @property {string} pageHash - ページのハッシュ。
 * @property {string} pageDir - ページのディレクトリパス。
 */

export default class SmoothScroll {
  constructor() {
    this.scrollSpeed = 500;
    this.offsetHeight = 50;
    this.pageHash = window.location.hash;
    this.pageDir = window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf("/") + 1,
    );
    this.init();
  }

  init() {
    this.updateHashLinks();
    window.addEventListener("load", () => {
      this.addClickListeners();
      if (window.performance.navigation.type === 0 && this.pageHash) {
        setTimeout(() => this.scrollToHash(), 200);
      }
    });
  }

  /**
   * ハッシュリンクを更新します。
   * @private
   */
  updateHashLinks() {
    const links = document.querySelectorAll('a[href*="#"]');
    links.forEach((element) => {
      const href = element.getAttribute("href");
      const path = href.replace(window.location.origin, "");
      const dir = path.substring(0, path.lastIndexOf("/") + 1);
      const hash = href.match(/#.+$/);
      if (hash && this.pageDir === dir) {
        element.setAttribute("href", hash);
      }
    });
  }

  getHeaderHeight() {
    if (window.innerWidth <= 1450) {
      const hamburger = document.querySelector(".js-hamburger");
      return hamburger ? hamburger.offsetHeight : 0;
    } else {
      const header = document.querySelector(".header");
      return header ? header.offsetHeight : 0;
    }
  }

  /**
   * イベントリスナー
   * @private
   */
  addClickListeners() {
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        const href = link.getAttribute("href");
        const target =
          href === "#" || href === ""
            ? document.documentElement
            : document.querySelector(href);
        if (target) {
          const position =
            target.getBoundingClientRect().top +
            window.scrollY -
            this.getHeaderHeight() -
            this.offsetHeight;
          window.scroll({ top: position, left: 0, behavior: "smooth" });
        }
      });
    });
  }

  /**
   *　スクロール
   * @private
   */
  scrollToHash() {
    const target = document.querySelector(this.pageHash);
    if (target) {
      const position =
        target.getBoundingClientRect().top +
        window.scrollY -
        document.querySelector(".header").offsetHeight -
        this.offsetHeight;
      window.scroll({ top: position, left: 0, behavior: "smooth" });
    }
  }
}
