export default class Gnav {
  constructor() {
    this.toggleHover();
  }

  // 親要素をホバーした場合も小要素にホバーエフェクトを加える
  toggleHover() {
    const targets = ['.js-toggleHover'];
    targets.forEach(parent => {
      document.querySelectorAll(parent).forEach(elm => {
        elm.addEventListener('mouseover', (event) => {
          event.target.closest(parent).classList.add('hover');
        });
        elm.addEventListener('mouseleave', (event) => {
          event.target.classList.remove('hover');
        });
      });
    });
  }

}

