export default class Modal {
  /**
   * ポップアップクラスのコンストラクタ
   * 必要なDOM要素の取得と初期化を行う
   */
  constructor() {
    // 必要なDOM要素を取得
    this.popup = document.getElementById('js-popup');
    if (!this.popup) return;

    this.blackBg = document.getElementById('js-black-bg');
    this.closeBtn = document.getElementById('js-close-btn');
    this.showBtns = document.getElementsByClassName('js-show-popup');
    this.video = document.querySelector('.popup-video');
    this.scrollPosition = 0;

    // // ピンチズーム関連の要素とステート
    // this.pinchArea = document.querySelector('.pinch-area');
    // this.content = document.querySelector('.content');
    // this.zoomState = {
    //   scale: 1,
    //   initialDistance: null,
    //   startX: 0,
    //   startY: 0,
    //   translateX: 0,
    //   translateY: 0,
    //   isDragging: false,
    //   isUpdating: false
    // };

    this.init();
  }

  /**
   * 初期化処理
   * イベントリスナーのバインドを行う
   */
  init() {
    this.bindEvents();
    // if (this.pinchArea && this.content) {
    //   this.initPinchZoom();
    // }
  }

  // /**
  //  * ピンチズーム機能の初期化
  //  */
  // initPinchZoom() {
  //   this.pinchArea.addEventListener('touchstart', this.handleTouchStart.bind(this));
  //   this.pinchArea.addEventListener('touchmove', this.handleTouchMove.bind(this));
  //   this.pinchArea.addEventListener('touchend', this.handleTouchEnd.bind(this));
  // }

  // /**
  //  * 2本の指の間の距離を計算
  //  */
  // getDistance(touches) {
  //   const [touch1, touch2] = touches;
  //   return Math.hypot(
  //     touch2.clientX - touch1.clientX,
  //     touch2.clientY - touch1.clientY
  //   );
  // }

  // /**
  //  * ズーム処理
  //  */
  // handleZoom(touches) {
  //   const currentDistance = this.getDistance(touches);
  //   if (this.zoomState.initialDistance) {
  //     const scaleChange = currentDistance / this.zoomState.initialDistance;
  //     this.zoomState.scale = Math.max(1, Math.min(3, this.zoomState.scale * scaleChange));

  //     if (this.zoomState.scale === 1) {
  //       this.zoomState.translateX = 0;
  //       this.zoomState.translateY = 0;
  //     }

  //     this.requestUpdate();
  //     this.zoomState.initialDistance = currentDistance;
  //   }
  // }

  // /**
  //  * コンテンツのtransform更新
  //  */
  // updateTransform() {
  //   const { translateX, translateY, scale } = this.zoomState;
  //   this.content.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scale})`;
  //   this.zoomState.isUpdating = false;
  // }

  // /**
  //  * 描画更新のリクエスト
  //  */
  // requestUpdate() {
  //   if (!this.zoomState.isUpdating) {
  //     this.zoomState.isUpdating = true;
  //     requestAnimationFrame(() => this.updateTransform());
  //   }
  // }

  // /**
  //  * タッチスタートイベントハンドラ
  //  */
  // handleTouchStart(e) {
  //   if (e.touches.length === 2) {
  //     this.zoomState.initialDistance = this.getDistance(e.touches);
  //   } else if (e.touches.length === 1 && this.zoomState.scale > 1) {
  //     this.zoomState.isDragging = true;
  //     this.zoomState.startX = e.touches[0].clientX - this.zoomState.translateX;
  //     this.zoomState.startY = e.touches[0].clientY - this.zoomState.translateY;
  //   }
  // }

  // /**
  //  * タッチムーブイベントハンドラ
  //  */
  // handleTouchMove(e) {
  //   if (e.touches.length === 2) {
  //     e.preventDefault();
  //     this.handleZoom(e.touches);
  //   } else if (this.zoomState.isDragging && e.touches.length === 1) {
  //     e.preventDefault();
  //     this.zoomState.translateX = e.touches[0].clientX - this.zoomState.startX;
  //     this.zoomState.translateY = e.touches[0].clientY - this.zoomState.startY;
  //     this.requestUpdate();
  //   }
  // }

  // /**
  //  * タッチエンドイベントハンドラ
  //  */
  // handleTouchEnd() {
  //   this.zoomState.initialDistance = null;
  //   this.zoomState.isDragging = false;
  // }

  /**
   * イベントリスナーの設定
   * クリックイベントなどの各種イベントを紐付ける
   */
  bindEvents() {
    // 背景クリックで閉じる
    if (this.blackBg) {
      this.blackBg.addEventListener('click', () => {
        this.closePopup()
      });
    }

    // 閉じるボタンのイベント設定
    if (this.closeBtn) {
      this.closeBtn.addEventListener('click', () => this.closePopup());
    }

    // 表示ボタンのイベント設定
    // HTML collectionを配列に変換してから処理
    Array.from(this.showBtns).forEach(btn => {
      btn.addEventListener('click', () => this.showPopup());
    });
  }

  /**
   * 背景スクロールを無効にする
   * スクロール位置を保持してbodyを固定する
   */
  disableScroll() {
    this.scrollPosition = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${this.scrollPosition}px`;
    document.body.style.width = '100%';
  }

  /**
   * 背景スクロールを有効にする
   * 保持していたスクロール位置に戻す
   */
  enableScroll() {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo(0, this.scrollPosition);
  }

  /**
   * ポップアップを表示する
   * is-showクラスを追加して表示状態にする
   */
  showPopup() {
    this.popup.classList.add('is-show');
    this.disableScroll(); // 背景スクロールを無効化
  }

  /**
   * ポップアップを閉じる
   * is-showクラスを除去し、動画が存在する場合は停止する
   */
  closePopup() {
    console.log('test')
    this.popup.classList.remove('is-show');
    this.enableScroll(); // 背景スクロールを有効化
    // 動画が存在する場合は停止
    if (this.video) {
      this.video.pause();
    }
  }

  /**
   * インスタンスの破棄
   * イベントリスナーを解除してメモリリークを防ぐ
   * ※ 必要に応じて使用する
   */
  destroy() {
    // スクロールを有効に戻しておく
    if (this.popup.classList.contains('is-show')) {
      this.enableScroll();
    }

    // 各イベントリスナーの解除
    if (this.blackBg) {
      this.blackBg.removeEventListener('click', this.closePopup);
    }
    if (this.closeBtn) {
      this.closeBtn.removeEventListener('click', this.closePopup);
    }
    Array.from(this.showBtns).forEach(btn => {
      btn.removeEventListener('click', this.showPopup);
    });
  }
}
