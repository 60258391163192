/**
 * 各月の最新ニュースの前にマーカーと月見出しを追加するクラス
 */
export default class NewsReleaseMarker {
  /** @type {HTMLElement[]} 月の最新ニュースを示すクラスを持つ要素のリスト */
  monthlyLatestItems;

  constructor() {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  /**
   * 初期化処理
   * @private
   */
  init() {
    try {
      // m1からm12までのクラスを持つ要素を全て取得
      this.monthlyLatestItems = [];
      for (let i = 1; i <= 12; i++) {
        const monthClass = `m${i.toString().padStart(2, '0')}`;
        const items = document.querySelectorAll(`.${monthClass}`);
        items.forEach(item => {
          this.monthlyLatestItems.push({
            element: item,
            month: i
          });
        });
      }

      if (this.monthlyLatestItems.length === 0) {
        console.warn('No monthly latest items found');
        return;
      }

      this.addMonthHeaders();
    } catch (error) {
      console.error('Failed to initialize NewsReleaseMarker:', error);
    }
  }

  /**
   * 各月の最新ニュースの前に月見出しを追加
   * @private
   */
  addMonthHeaders() {
    this.monthlyLatestItems.forEach(({element, month}) => {
      const monthHeader = document.createElement('li');
      monthHeader.classList.add('month-li');
      monthHeader.innerHTML = `<h3 class="news-release__month-num">${month}月</h3>`;
      element.parentNode.insertBefore(monthHeader, element);
    });
  }

  /**
   * マーカーと月見出しを全て削除
   * @public
   */
  removeAll() {
    document.querySelectorAll('.news-release__monthly-marker').forEach(marker => {
      marker.remove();
    });
    document.querySelectorAll('.news-release__month-num').forEach(header => {
      header.closest('li')?.remove();
    });
  }

  /**
   * マーカーと月見出しを再描画
   * @public
   */
  refresh() {
    this.removeAll();
    this.init();
  }
}
